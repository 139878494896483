<template>
  <div class="c-app modal-vue farm-page container-page reports miner-info">
    <CWrapper class="bg-bg-gray">
      <CRow class="d-flex justify-content-between align-items-center mb-4" v-if="reports">
        <CCol md="9">
          <form class="c-miners-filtration mb-2 d-flex flex-wrap">
            <CCol md="3" class="pl-0 pr-0 pr-lg-3">
              <label for="">{{ $t("Farm") }}</label>
              <div class="position-relative farm-select">
                <CCard
                  @click="collapseItem('collapse1')"
                  class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
                >
                  <span class="text-black targetCardtext">{{ $t("Farm") }}</span>
                  <img
                    src="@/assets/icons/arrow-select.svg"
                    alt=""
                    class="targetCardtext"
                  />
                </CCard>
                <CCollapse
                  :show="collapseItems['collapse1']"
                  class="collapseCard w-100 border-0 position-absolute"
                >
                  <CCard class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card">
                    <p
                      class="d-flex align-items-center relative"
                      v-for="(obj, i) in getters.farms"
                      :key="i"
                    >
                      <input
                        type="checkbox"
                        :id="'h' + i"
                        name="farms"
                        :checked="checkers['farms']"
                        class="d-inline w-auto targetCardtext"
                        :value="obj.key"
                        @change="(e) => changeValue(e, i, 'farm')"
                      />
                      <!--                      <span class="checkmark"></span>-->
                      <label :for="'h' + i" class="w-100 mb-0 ml-3 targetCardtext">{{
                        obj.value
                      }}</label>
                    </p>
                  </CCard>
                </CCollapse>
              </div>
            </CCol>
            <CCol md="3" class="pl-0 pr-0 pr-lg-3">
              <label for="">{{ $t("Containers") }}</label>
              <div class="position-relative farm-select">
                <CCard
                  @click="collapseItem('collapse2')"
                  class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
                >
                  <span class="text-black targetCardtext">{{ $t("Containers") }}</span>
                  <img
                    src="@/assets/icons/arrow-select.svg"
                    alt=""
                    class="targetCardtext"
                  />
                </CCard>
                <CCollapse
                  :show="collapseItems['collapse2']"
                  class="collapseCard w-100 border-0 position-absolute"
                >
                  <CCard class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card">
                    <p
                      class="d-flex align-items-center relative"
                      v-for="(obj, i) in getters.containers"
                      :key="i"
                    >
                      <input
                        type="checkbox"
                        :id="'containers' + i"
                        name="farms"
                        :checked="checkers['containers']"
                        class="d-inline w-auto targetCardtext"
                        :value="obj.key"
                        @change="(e) => changeValue(e, i, 'container')"
                      />
                      <!--                      <span class="checkmark"></span>-->
                      <label
                        :for="'containers' + i"
                        class="w-100 mb-0 ml-3 targetCardtext"
                        >{{ obj.value }}</label
                      >
                    </p>
                  </CCard>
                </CCollapse>
              </div>
            </CCol>
            <CCol md="3" class="pl-0 pr-0 pr-lg-3">
              <label for="">{{ $t("Miners") }}</label>
              <div class="position-relative farm-select">
                <CCard
                  @click="collapseItem('collapse3')"
                  class="myCard w-100 farmCard shadow-none m-0 p-2 text-black d-flex flex-row justify-content-between align-items-center cursor-pointer"
                >
                  <span class="text-black targetCardtext">{{ $t("Miners") }}</span>
                  <img
                    src="@/assets/icons/arrow-select.svg"
                    alt=""
                    class="targetCardtext"
                  />
                </CCard>
                <CCollapse
                  :show="collapseItems['collapse3']"
                  class="collapseCard w-100 border-0 position-absolute"
                >
                  <CCard
                    class="farmCard2 w-100 shadow-none p-2 mt-0 mb-0 radio_card"
                    @scroll="onScrollMiners"
                  >
                    <p
                      class="d-flex align-items-center relative"
                      v-for="(obj, i) in miners.items"
                      :key="i"
                    >
                      <input
                        type="checkbox"
                        :id="'miners' + i"
                        name="miners"
                        :checked="checkers['miners']"
                        class="d-inline w-auto targetCardtext"
                        :value="obj.key"
                        @change="(e) => changeValue(e, i, 'miner')"
                      />
                      <!--                      <span class="checkmark"></span>-->
                      <label :for="'miners' + i" class="w-100 mb-0 ml-3 targetCardtext">{{
                        obj.name
                      }}</label>
                    </p>
                  </CCard>
                </CCollapse>
              </div>
            </CCol>
            <!-- <CCol md="2" class="d-flex pl-0 pr-0 pr-lg-3">
              <CButton class="button-blue text-uppercase" @click="filter()">{{
                $t("apply")
              }}</CButton>
            </CCol> -->
          </form>
        </CCol>
        <CCol md="3" class="justify-content-end d-flex mt-3">
          <CButton
            class="button-white w-auto h-auto rounded px-4 mobile-50"
            @click="generatePDF"
            >PDF</CButton
          >
          <CButton
            class="button-blue w-auto ml-3 h-auto xls-hover px-4 mobile-50"
            @click="downloadExcel"
            >XLS</CButton
          >
        </CCol>
      </CRow>
      <div class="c-title pb-4">{{ $t("Report") }}</div>
      <div class="d-flex align-items-end calendar">
        <div>
          <label for="from">{{ $t("From") }}</label>
          <Datepicker
            :format="customFormatter"
            v-model="date.from"
            class="data-pointer"
          />
        </div>
        <div>
          <label for="from">{{ $t("To") }}</label>
          <Datepicker :format="customFormatter" v-model="date.to" class="data-pointer" />
        </div>
        <CButton class="button-blue" @click="getReportsByDate()">{{
          $t("Show")
        }}</CButton>
      </div>
      <div class="reports-result mt-2" id="reports" v-if="reports">
        <div class="text-main_black d-flex mt-4 pl-4"></div>
        <table class="table mt-3">
          <thead>
            <th class="pl-4">{{ $t("Farm") }}</th>
            <th>{{ $t("Avg Hashrate") }}</th>
            <th v-if="reports.filter(obj => obj.container_name != undefined).length > 0">{{ $t('Container') }}</th>
            <th>{{ $t("Avg Temp, C") }}</th>
            <th>{{ $t("Efficiency") }}</th>
            <th>Sum of mach. hours</th>
          </thead>
          <tbody>
            <tr
              class="cursor-pointer"
              v-for="(report, i) in reports"
              :key="i"
              @click="clickToList(report)"
            >
              <td class="pl-4">{{ report.name }}</td>
              <td>{{ convertHashrate(report.hashrate) }}</td>
              <td v-if="report.container_name">{{ report.container_name }}</td>
              <td>{{ report.temperature }} С</td>
              <td>{{ report.efficiency }} %</td>
              <td>{{ report.clock }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { storeParent } from "../../api/composition/filtering";
import { computed, reactive, ref } from "@vue/composition-api";
import axios from "axios";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";

export default {
  name: "Miners",
  components: {
    Datepicker,
  },
  setup(props, { root }) {
    const _store = storeParent(root);
    const _dispatch = _store.dispatchComposition();
    const collapseItems = reactive({
      collapse1: false,
      collapse2: false,
      collapse3: false,
    });
    const helpers = _store.helpers();
    const choosenContainers = ref(null);
    const date = reactive({
      from: "",
      to: "",
    });
    const checkers = reactive({
      miners: null,
      farms: null,
      containers: null,
    });
    const filterFields = reactive({
      data: "",
      farmsId: "1",
      dateBegin: "2021-10-10",
      dateEnd: "2021-10-14",
    });
    const filterCachings = reactive({ cache: {}, farms: {}, containers: {},miners: {} });
    const reports = ref([]);
    const changeValue = (value, index, target) => {
      switch (target) {
        case "farm":
          filterCachings.farms = helpers.computeMultipleChoose(
            filterCachings.farms,
            index,
            getters.value.farms
          );
          filterFields.data = changeFarm(filterCachings.farms);
          break;
        case "container":
          filterCachings.containers = helpers.computeMultipleChoose(
            filterCachings.containers,
            index,
            getters.value.containers
          );
          filterFields.data = changeFarm(filterCachings.containers);
          break;
        case "miner":
          filterCachings.miners = helpers.computeMultipleChoose(
            filterCachings.miners,
            index,
            miners.items
          );
          filterFields.data = changeFarm(filterCachings.miners);
          break;
      }
      if (target == "farm") {
        _dispatch.getContainers(filterFields.data);
        choosenContainers.value = null;
      } else if (target == "container") {
        choosenContainers.value = filterFields.data;
        _dispatch.getMiners({ page: 1, body: filterFields.data }).then((res) => {
          miners.items = res.data;
        });
      }
      filterFields.field = target;
    };

    const changeFarm = (cache) => {
      return Object.keys(cache)
        .map((item) => {
          return cache[item];
        })
        .toString();
    };

    const scrolls = ref(1);

    const downloadExcel = () => {
      axios({
        method: "POST",
        url: process.env.VUE_APP_API + "/api/reports/xls",
        data: { [filterFields.field + "s"]: filterFields.data, ...date },
        headers: {
          Authorization: `Bearer ` + window.localStorage.getItem("btsminer_token"),
        },
        responseType: "blob",
      }).then((res) => {
        fileDownload(res.data, "report.xlsx");
      });
    };

    const clickToList = (target) => {
      const curDate = typeof date.to == "string" ? new Date(date.to) : date.to;
      let toDate = date.from;
      // const numberOfDays = Math.ceil((curDate - toDate) / (1000 * 3600 * 24));
      // if(numberOfDays > 90){
      //   toDate = curDate.getDate() - 90;
      // }
      const newMiners = changeFarm(filterCachings.miners)
      const newContainers = changeFarm(filterCachings.containers)
      let targetField = 'farms'
      let targetValue = target.id
      if(newMiners.length > 0){
        targetField = 'miners'
        targetValue = newMiners.toString()
      }
      else if(target.container_name){
        targetField = 'containers'
        targetValue = target.container_id
      }

      root.$router.push({
          name: 'statistics',
          query: {
            [targetField]: targetValue,
            farmName: target.name,
            containerName: newContainers.length > 0 ? getters.value.containers.filter(res => newContainers.includes(res.id)).map(res => res.value) : (target.container_name ? [target.container_name] : null),
            minersNames: newMiners.length > 0 ? miners.items.filter(res => newMiners.includes(res.id)).map(res => res.name) : null,
            dateFrom: toFormat(toDate),
            dateTo: toFormat(curDate)
          }
        }
      );
      // `/Statistics?farms=${target.id}&dateFrom=${toFormat(toDate)}&dateTo=${toFormat(
      //     curDate
      //   )}`
    };

    const filter = () => {
      Object.keys(collapseItems).forEach((res) => {
        collapseItems[res] = false;
      });
      _dispatch
        .getReports({ [filterFields.field + "s"]: filterFields.data, ...date })
        .then((res) => {
          reports.value = res.data.data;
        });
    };

    _dispatch.getFarms();
    _dispatch.getContainers();
    _dispatch.getMiners(1).then((res) => {
      miners.items = res.data;
    });

    const getters = computed(() => _store.gettersComposition());
    const miners = reactive({ items: [] });

    const getReportsByDate = () => {
      if (date.from === "") {
        return;
      }
      date.to = date.to === "" ? toFormat(new Date()) : date.to;
      if(Object.keys(filterCachings.miners).length > 0){
        filterFields.data = changeFarm(filterCachings.miners)
        filterFields.field = "miner"
      }
      else if(Object.keys(filterCachings.containers).length > 0){
        filterFields.data = changeFarm(filterCachings.containers)
        filterFields.field = "container"
      }
      else{
        filterFields.data = changeFarm(filterCachings.farms)
        filterFields.field = "farm"
      }
      _dispatch
        .getReports({ [filterFields.field + "s"]: filterFields.data, ...date, 
          from: toFormat(date.from),  
          to: toFormat(date.to) 
        })
        .then((res) => {
          reports.value = res.data.data;
        });
    };

    const customFormatter = (date) => {
      // custom formatter
      return moment(date).format("DD/MM/YYYY");
    };

    const toFormat = (date) => {
      return moment(date).format(`YYYY-MM-DD`);
    };

    const onScrollMiners = (e) => {
      if (getters.value.miners.length === 0) return;
      const element = e.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 2) {
        scrolls.value += 1;
        if (choosenContainers)
          _dispatch.getMiners({
            page: scrolls.value,
            body: choosenContainers.value,
          });
        else _dispatch.getMiners(scrolls.value);
        miners.items = [...miners.items, ...getters.value.miners];
      }
    };

    const collapseItem = (name) => {
      collapseItems[name] = !collapseItems[name];
      Object.keys(collapseItems).forEach((res) => {
        if (res != name) {
          collapseItems[res] = false;
        }
      });
    };

    return {
      getters,
      onScrollMiners,
      collapseItem,
      date,
      reports,
      collapseItems,
      clickToList,
      customFormatter,
      getReportsByDate,
      changeValue,
      miners,
      downloadExcel,
      checkers,
      filter,
    };
  },
  data() {
    return {
      collapseEfficiency: false,
      modalInfo: {
        curUser: null,
        date: null,
        date2: null,
        collapseType: false,
        collapseStatus: false,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector("body").addEventListener("click", (e) => {
        if (typeof e.target.className != "string") {
          return;
        }
        if (
          e.target.type !== "checkbox" &&
          !e.target.className.includes("farmCard2") &&
          !e.target.className.includes("collapseCard") &&
          !e.target.className.includes("myCard") &&
          !e.target.className.includes("farm-select") &&
          !e.target.className.includes("targetCardtext")
        ) {
          this.collapseItems.collapse1 = false;
          this.collapseItems.collapse2 = false;
          this.collapseItems.collapse3 = false;
        }
      });
    });
  },
  created() {
    this.curPage();
  },
  watch: {
    // observe date changes
    "modalInfo.date": {
      handler: function (e) {
        if (!e) return;
        this.modalInfo.curUser.date_birth =
          this.modalInfo.date.getFullYear() +
          "-" +
          (this.modalInfo.date.getMonth() + 1) +
          "-" +
          this.modalInfo.date.getDate();
      },
      deep: true,
    },
  },
  methods: {
    curPage() {
      this.$emit("curPage", "");
    },
    generatePDF() {
      const doc = new jsPDF("l", "pt", "a3");
      doc.html(document.querySelector("#reports"), {
        callback: function (pdf) {
          pdf.save("statistics.pdf");
        },
      });
    },
  },
};
</script>
